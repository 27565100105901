.df-search {
  position: relative;

  span.bi-search {
    color: #ADB5BD;
    font-size: 20px;
    position: absolute;
    top: 6px;
    left: 8px;
  }

  input {
    text-indent: 25px;

    &::placeholder {
      color: #adb5bd;
    }
  }
}