/* Autocomplete */
.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 4px 25px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #0D6EFD; color: #fff; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 5px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }
.autocomplete-suggestion {
    cursor: pointer;
}
.autocomplete-group {
    font-weight: 600;
}
