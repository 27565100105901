/*
 * Переопределение переменных Bootstrap.
 */
// Global settings
$body-bg: #ffffff;
$font-family-base: "Inter", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.45;

$link-color: #0857a4;

$input-bg: #fff;



$offcanvas-border-width: 0px;


// Pagination
$pagination-color: #343A40;
$pagination-border-color: #dfe2e6;

$pagination-hover-color: #343A40;
$pagination-hover-bg: #fff;
$pagination-hover-border-color: #adb5bd;

$pagination-active-bg: #495057;
$pagination-active-border-color: #495057;

$pagination-disabled-bg: #f8f9fa;


// Nav
$nav-link-font-size: 16px;
//$nav-link-font-weight: 300;

$nav-tabs-link-hover-border-color: #ADB5BD;


// pill
$nav-pills-border-radius: 25px;
$nav-pills-link-active-bg: #495057;
