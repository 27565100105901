// Toasts & alerts
.toast-header {
  &.df-success {
    color: $df-text-success;
    //color: $green-100;
    background-color: $df-bg-success;
  }
  &.df-danger {
    color: $df-text-danger;
    background-color: $df-bg-danger;
  }
  &.df-warning {
    color: $df-text-warning;
    background-color: $df-bg-warning;
  }
  &.df-info {
    color: $df-text-info;
    background-color: $df-bg-info;
  }
}

.table-check {
  font-size: 15px;

  &.bool-false {
    color: $bool-false-color;
  }
  &.bool-true {
    color: $bool-true-color;
  }
}

// Table columns

.df-w-60 {
  width: 60px !important;
  min-width: 60px;
  max-width: 60px;
}

.df-w-120 {
  width: 120px !important;
  min-width: 120px;
  max-width: 120px;
}

.df-w-136 {
  width: 136px !important;
  min-width: 136px;
  max-width: 136px;
}

.df-w-142 {
  width: 142px !important;
  min-width: 142px;
  max-width: 142px;
}

.df-w-190 {
  width: 190px !important;
  min-width: 190px;
  max-width: 190px;
}

.df-w-256 {
  width: 256px !important;
  min-width: 256px;
  max-width: 256px;
}
.df-w-262 {
  width: 262px !important;
  min-width: 262px;
  max-width: 262px;
}
.df-w-341 {
  width: 341px !important;
  min-width: 341px;
  max-width: 341px;
}
.df-w-496 {
  width: 496px !important;
  min-width: 496px;
  max-width: 496px;
}


// Paddings
.pb-10px {
  padding-bottom: 10px;
}
.pb-9px {
  padding-bottom: 9px;
}
.pt-10px {
  padding-top: 10px;
}
.pt-9px {
  padding-top: 9px;
}

// Text utils
.df-text-disabled {
  color: #adb5bd !important;
}

// Color utils
// Например в товарных знаках сертификации
.df-active-box {
  background-color: #d1e7dd;
  border: 2px solid #198754;
  border-radius: 6px;
}
.df-inactive-box {
  background-color: inherit;
  border: 1px solid #e9e9e9;
}

.df-box {
  align-items: center;
  min-width: 100px;
  height: 100px;
  max-width: 101px !important;
  max-height: 101px !important;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 12px;
    cursor: pointer;
  }
}
