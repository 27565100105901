.upload_dropZone,
.alreadyUploaded {
  background: var(--color-accent-primary, #F0F2F4);
  outline: 1px dashed var(--color-accent-primary-active, #adb5bd);
  outline-offset: -12px;
  height: 400px;
  max-height: 400px;

  &.additional {
    height: 288px;
    max-height: 288px;
    width: 100%;
  }

  &__content {

  }

  &.highlight {
    outline-offset: -4px;
    outline-color: var(--color-a, #adb5bd);
    background: var(--color-b, #dee2e6);
  }

  .box__input {
    vertical-align: baseline;
  }
}

.alreadyUploaded {
  outline: none;
}
