
.df-translatable-history {

  ul {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 100;

    li {
      display: block;

      &:hover {
        background-color: $gray-300;
      }
    }
  }

}

.df-input-has-draft {
  color: var(--bs-warning-rgb) !important;
//  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
//<path d="M5.75094 8.62667C5.7101 8.72524 5.68909 8.8309 5.68909 8.9376C5.68909 9.15308 5.77469 9.35975 5.92706 9.51212C6.07944 9.66449 6.2861 9.7501 6.50159 9.7501C6.71708 9.7501 6.92374 9.66449 7.07611 9.51212C7.22849 9.35975 7.31409 9.15308 7.31409 8.9376C7.31409 8.8309 7.29307 8.72524 7.25224 8.62667C7.21141 8.52809 7.15156 8.43852 7.07611 8.36307C7.00067 8.28762 6.9111 8.22778 6.81252 8.18694C6.71394 8.14611 6.60829 8.1251 6.50159 8.1251C6.39489 8.1251 6.28924 8.14611 6.19066 8.18694C6.09208 8.22778 6.00251 8.28762 5.92706 8.36307C5.85162 8.43852 5.79177 8.52809 5.75094 8.62667Z" fill="#FFC107"/>
//<path d="M5.80056 3.75436C5.76875 3.8524 5.75789 3.95603 5.76871 4.05853L6.05309 6.90797C6.06264 7.01991 6.11386 7.12419 6.19661 7.20017C6.27936 7.27616 6.38762 7.31833 6.49996 7.31833C6.61231 7.31833 6.72057 7.27616 6.80332 7.20017C6.88607 7.12419 6.93728 7.01991 6.94684 6.90797L7.23121 4.05853C7.24203 3.95603 7.23118 3.8524 7.19936 3.75436C7.16754 3.65632 7.11547 3.56606 7.04652 3.48945C6.97756 3.41283 6.89327 3.35157 6.79912 3.30964C6.70496 3.2677 6.60304 3.24603 6.49996 3.24603C6.39689 3.24603 6.29497 3.2677 6.20081 3.30964C6.10665 3.35157 6.02236 3.41283 5.95341 3.48945C5.88446 3.56606 5.83238 3.65632 5.80056 3.75436Z" fill="#FFC107"/>
//<path fill-rule="evenodd" clip-rule="evenodd" d="M11.0962 11.0962C9.87721 12.3152 8.22391 13 6.5 13C4.77609 13 3.12279 12.3152 1.90381 11.0962C0.68482 9.87721 0 8.22391 0 6.5C0 4.77609 0.68482 3.12279 1.90381 1.90381C3.12279 0.68482 4.77609 0 6.5 0C8.22391 0 9.87721 0.68482 11.0962 1.90381C12.3152 3.12279 13 4.77609 13 6.5C13 8.22391 12.3152 9.87721 11.0962 11.0962ZM10.5217 10.5217C9.45506 11.5883 8.00842 12.1875 6.5 12.1875C4.99158 12.1875 3.54494 11.5883 2.47833 10.5217C1.41172 9.45506 0.8125 8.00842 0.8125 6.5C0.8125 4.99158 1.41172 3.54494 2.47833 2.47833C3.54494 1.41172 4.99158 0.8125 6.5 0.8125C8.00842 0.8125 9.45506 1.41172 10.5217 2.47833C11.5883 3.54494 12.1875 4.99158 12.1875 6.5C12.1875 8.00842 11.5883 9.45506 10.5217 10.5217Z" fill="#FFC107"/>
//</svg>

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FFC107'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FFC107' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
