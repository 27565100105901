#controlButtons {
  box-shadow: 0px 8px 35px 0px #00000029;
  height: 52px;
  max-height: 52px;

  margin-left: -1rem; // компенсируем container
  padding: 0.5rem;
  //.btn {
  //  height: 38px;
  //}
}
