$offcanvas-backdrop-bg: #fff;
$offcanvas-backdrop-opacity: 0;

.df-sidebar {
  top: calc($header-height + 2px) !important;
  width: 320px !important;

  .df-sidebar-item-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 0 0 36px;

    &__name {
      padding-left: 4px;
    }
  }

  .df-sidebar-item-options {
    padding: 0 0 0 52px;

    .df-sidebar-subitems {
      li {
        margin-bottom: 3px;

        & a {
          padding: 3px 8px;
          font-size: 14px;
          font-weight: 300;
          line-height: 21px;
          color: #343A40;
          -webkit-transition: background .3s;
          transition: background .3s;

          &:hover {
            background: #CFE2FF;
            color: #343A40;
          }
        }
      }
    }
  }
}