$df-page-navigation-bg-color: #F0F2F4;
$df-blue-color: #0D6EFD;
$nav-link-font-weight: 500;

$breadcrumb-font-size: 16px;
$breadcrumb-margin-bottom: 0;

.df-page-navigation {
  background-color: $df-page-navigation-bg-color;
  line-height: 24px;

  .df-page-navigation-breadcrumb {
    > .breadcrumb {
      line-height: 24px;

      .breadcrumb-item {
        > a {
          color: $df-blue-color;
          text-decoration: none;

          &:hover {
            border-bottom: 1px solid $df-blue-color;
          }
        }
      }
    }
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
  }

  .df-page-navigation-tabs {
    &.nav-underline {

      .nav-link {
        color: #343a40;
        font-size: 16px;
        font-weight: $nav-link-font-weight;
        padding: 0 0 0.3rem 0;

        &:hover {
          border-bottom-color: #adb5bd;
        }

        &.active {
          color: #343a40;
          //font-weight: $nav-link-font-weight;
          border-bottom-color: $df-blue-color;
        }
      }
    }
  }
}
