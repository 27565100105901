// Layout

// Page header
$header-height: var(--header-height, 52px);
$header-bg-color: #fff;
$header-font-size: 14px;

$header-zindex: 1000;
$zindex-dropdown-menu: 1200;

$border-radius-default: 2px;


// Colors
$bool-false-color: #adb6bd;
$bool-true-color: #479f76;

$df-bg-success: #D1E7DD;
$df-text-success: #0F5132;
$df-bg-danger: #F8D7DA;
$df-text-danger: #842029;
$df-bg-warning: #FFF3CD;
$df-text-warning: #664D03;
$df-bg-info: #F0F2F4;
$df-text-info: #343A40;

// Tables
$table-thead-bg-color: #f8f9fa;
