
.df-header-navbar {
  background-color: $header-bg-color;
  height: $header-height;
  font-size: $header-font-size;
  font-weight: 400;
  line-height: 21px;

  .df-header-brand {
    margin: 0 auto;

    .df-header-burger {
      width: 52px;

      button.btn {
        width: 100%;
        height: 100%;
        padding: 10px;
      }

      .navbar-toggler {
        span {
          display: block;
          background-color: #343a40;
          height: 2px;
          width: 22px;
          margin-top: 5px;
          margin-bottom: 5px;
          position: relative;
          left: 10px;
          opacity: 1;
          transition: all 0.25s ease-out;
          transform-origin: center left;

          :nth-child(1) {
            transform: translate(0%, 0%) rotate(0deg);
          }

          :nth-child(2) {
            opacity: 1
          }

          :nth-child(3) {
            transform: translate(0%, 0%) rotate(0deg);
          }

          :nth-child(1) {
            margin-top: 0.3em;
          }

        }

        &:not(.collapsed) {
          span {
            &:nth-child(1) {
              transform: translate(15%, -33%) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0
            }

            &:nth-child(3) {
              transform: translate(15%, 33%) rotate(-45deg);
            }
          }
        }
      }
    }

    .df-header-logo {
      padding: 13px 10px 15px 10px;

      img {
        height: 24px;
      }
    }

  }
}
