@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Tables
//$table-hover-bg-factor: 1;
$table-hover-bg: f8f9fa;

.table.df-table {
    width: auto;

    > tbody {
      > tr {
        &:hover {
          > * {
            //$table-hover-color
            background-color: $table-thead-bg-color;  // !important;
          }
        }
      }
    }
    > thead {
      th {
        border-bottom: none;
      }
    }

    th {
      font-weight: 600;
      color: $gray-800;
    }

    td {
      color: $gray-800;
    }

    a {
      color: #0D6EFD;
      text-decoration: none;

      //&.df-link:hover {
      //  text-decoration: none;
      //  border-bottom: none;
      //}

      &.df-link-dotted {
        stroke-width: 2px;
        border-bottom: 1px dotted;
      }

      &:hover:not(.dropdown-item, .df-link-dotted, .df-link) {
        border-bottom: 1px solid #0D6EFD;
      }
      &.df-link-dotted:hover {
        .df-link-dotted {
          border-bottom: none;
        }
      }

      &.btn {
        &:hover {
          color: #fff;
        }
      }
    }
}


$df-table-variants: (
  "changelist": $table-thead-bg-color
);

$table-variants: map-merge($table-variants, $df-table-variants);
