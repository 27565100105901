.df-form {
  &__field {
    &--autocomplete {
      position: relative;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        z-index: 1300;
        top: 100%;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
        display: block;
        background-color: #ffffff;
        border-bottom: 1px solid #F2F3F7;
        border-left: 1px solid #F2F3F7;
        border-right: 1px solid #F2F3F7;

        &.loading {
          height: 100px;
          position: absolute;

          &::after {
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            //background-image: url('../images/spinner.svg');
            background-size: 50px;
            background-position: center;
            background-repeat: no-repeat;
          }
        }

        li {
          display: block;
          cursor: pointer;
          padding: 7px 14px;
          white-space: pre-wrap;

          &:hover,
          &:focus {
            color: white;
            background-color: #62a2ef;
          }
        }
      }
    }
  }
}
