$df-page-navigation-bg-color: #f0f2f4;
$df-blue-color: #0D6EFD;

.df-page-navigation {
  background-color: $df-page-navigation-bg-color;
  color: $gray-800;

  a.df-page-navigation-link {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    line-height: 32px;
  }

  .nav-link {
    font-weight: 500;

    .active {
      border-bottom-color: $df-blue-color;
    }

    &.disabled {
      color: #6c757d !important;
    }
  }
}
